/**
 * Основной модальный блок для выбора бронирования.
 * @type {Element}
 */
const selectBookingModal = app.querySelector('#select-booking');
const financeFormsSection = app.querySelector('.finance-forms');
/**
 * Слушатель событий для отслеживания изменений в полях для ввода номера бронирования и выбора отеля.
 * При соответствующем вводе отправляет запрос на сервер для получения информации о бронировании.
 */
async function selectBookingListener (event){
    const bookingNumberInput = app.querySelector('#booking-number');
    const bookingNumber = bookingNumberInput.value.trim();
    const hotelName = app.querySelector('.hotel-selector').value;

    // Проверяем условия активации запроса на сервер: правильный ввод номера брони и выбор отеля
    let response;
    if ((event && event.target.id.includes('booking-number') || !event)
        && validateBookingId(bookingNumber)
        && hotelName) {

        console.log(`valid booking id ${bookingNumber} & hotel ${hotelName}`);

        const spinner = document.getElementById(`booking-number-spinner`);
        spinner.classList.remove('hidden');
        bookingNumberInput.value = bookingNumberInput.value.trim();

        let data;
        try {
            response = await fetchBooking(hotelName, bookingNumber);
            data = response.result
        } catch (error) {
            data = null;
        }
        currentBookingData = data;

        spinner.classList.add('hidden');

        if (data && data.guestName) {
            const bookingSuccessfullyLoadedEvent = new CustomEvent('bookingSuccessfullyLoaded', {
                bubbles: true,
                cancelable: true,
                detail: data,
            });
            document.dispatchEvent(bookingSuccessfullyLoadedEvent);
        } else {
            const bookingFailureLoadedEvent = new CustomEvent('bookingFailureLoaded', {
                bubbles: true,
                cancelable: true,
                detail: response && response.result ? response.result : null,
            });
            document.dispatchEvent(bookingFailureLoadedEvent);
        }
    }
}
window.selectBookingListener = selectBookingListener;
selectBookingModal.addEventListener('change', selectBookingListener);

/**
 * Модальный блок с деталями бронирования.
 * @type {Element}
 */
const bookingDetailsModal = document.getElementById('booking-details-modal');

/**
 * @typedef {Object} BookingDetails
 * @property {string} guestName - Имя гостя.
 * @property {string} arrival - Дата прибытия в формате ISO (например, "2022-08-10T12:00:00Z").
 * @property {string} departure - Дата отъезда в формате ISO.
 * @property {string|number} room - Номер комнаты.
 * @property {string} fullHotelName - Полное имя отеля.
 * @property {?number|string} [hintLivingSum] - Сумма проживания (необязательное поле).
 */

/**
 * Функция отображения деталей бронирования на основе предоставленных данных.
 * @param {BookingDetails} data - Данные о бронировании.
 */
function showBookingDetails(data) {
    if (data) {
        document.getElementById('guest-name').textContent = data.guestName;
        document.getElementById('check-in-date').textContent = new Date(data.arrival).toLocaleDateString('ru-RU');
        document.getElementById('check-out-date').textContent = new Date(data.departure).toLocaleDateString('ru-RU');
        document.getElementById('room-number').textContent = data.room;
        document.getElementById('hotel-name').textContent = data.fullHotelName;
        bookingDetailsModal.classList.remove('hidden');

        // Проверяем авторизован ли пользователь (если в авторизации, но бронь уже прогружена, то кнопка "На главную" не нужно показывать, она после авторизации покажется)
        if (window.user) {
            goToMainMenuBtn.classList.remove('hidden');
        }
        selectBookingModal.classList.add('hidden');

        if (data.hintLivingSum && forms.querySelector('.accommodation-amount')) {
            app.querySelector('.accommodation-amount').value = data.hintLivingSum;
            app.querySelector('.payment-amount').value = data.hintLivingSum;
        }
    }
}

/**
 * Функция для переключения на окно выбора бронирования.
 */
async function showSelectBooking() {
    bookingDetailsModal.classList.add('hidden');
    selectBookingModal.classList.remove('hidden');
    await toggleMainMenu('show'); // Скрываем все элементы, кроме главного меню
}

/**
 * Обработчик события, который отслеживает клик вне окна с деталями бронирования.
 * Если клик совершен вне модального окна с деталями бронирования, возвращается к окну выбора бронирования.
 */
bookingDetailsModal.addEventListener('click', async (event) => {
    if (event.target === bookingDetailsModal && !bookingDetailsModal.classList.contains('hidden')) {
        await showSelectBooking();
    }
});


document.addEventListener('bookingSuccessfullyLoaded', (event) => {
    const bookingData = event.detail;
    showBookingDetails(bookingData);
});
document.addEventListener('bookingFailureLoaded', (event) => {
    if (event.detail === 'not found') {
        handleBookingLoadFailure('Не найдено бронирование с таким номером, проверьте выбранный объект');
    } else {
        handleBookingLoadFailure('Ошибка при загрузке, обновите страницу');
    }
});


/**
 * Обработчик ошибок, вызываемый при проблемах с загрузкой данных о бронировании.
 */
function handleBookingLoadFailure(text = "Ошибка при загрузке, обновите страницу") {
    const spinner = document.getElementById(`booking-number-spinner`);
    spinner.querySelector('.text-lg').textContent = text;
    spinner.classList.remove('hidden');
}

/**
 * Логика для добавления платежа за проживание или залога
 * @type {Element}
 */
const addIncomePaymentBtn = document.getElementById('add-income-payment'); // Элемент кнопки добавления платежа

/**
 * Логика для добавления платежа за проживание или залога
 * @type {Element}
 */
const addRefundPaymentBtn = document.getElementById('add-refund-payment'); // Элемент кнопки добавления платежа


/**
 * Логика для добавления доп.услуги в бронь bnovo
 * @type {Element}
 */
const addAdditionalServiceBtn = document.getElementById('add-additional-service'); // Элемент кнопки добавления доп.услуги

/**
 * Функция для отображения заданной формы.
 * @param {Element} formElement - Элемент формы для отображения.
 * @param {string} formType - Тип формы: income/refund
 */
function showForm(formElement, formType='') {
    formElement.classList.remove('hidden');  // Показываем форму
    financeFormsSection.classList.remove('hidden');
    bookingDetailsModal.classList.add('hidden');

    // Далее определяем header для формы сверху в зависимости от formType
    const incomeHeader = document.getElementById('income-header');
    const refundHeader = document.getElementById('refund-header');

    switch(formType) {
        case 'income':
            incomeHeader.style.display = 'block';
            refundHeader.style.display = 'none';
            break;
        case 'refund':
            incomeHeader.style.display = 'none';
            refundHeader.style.display = 'block';
            break;
        default:
            incomeHeader.style.display = 'none';
            refundHeader.style.display = 'none';
            break;
    }
}

/**
 * Функция для скрытия заданной формы.
 * @param {Element} formElement - Элемент формы для скрытия.
 */
function hideForm(formElement) {
    formElement.classList.add('hidden');  // Показываем форму
    financeFormsSection.classList.add('hidden');
    bookingDetailsModal.classList.remove('hidden');

}

/**
 * Обработчики событий, которые управляют отображением соответствующих форм при клике на заданные кнопки.
 */
addIncomePaymentBtn.addEventListener('click', () => {
  // Скрываем предыдущую форму если есть
  hideForm(addRefundPaymentForm);
  // Показываем нужную форму
  showForm(addIncomePaymentForm, 'income')
});

/**
 * Обработчики событий, которые управляют отображением соответствующих форм при клике на заданные кнопки.
 */
addRefundPaymentBtn.addEventListener('click', () => {
  // Скрываем предыдущую форму если есть
  hideForm(addIncomePaymentForm);
  // Показываем нужную форму
  showForm(addRefundPaymentForm, 'refund')
});

/**
 * Обработчики событий, которые управляют отображением модалки для добавления доп.услуги.
 */
addAdditionalServiceBtn.addEventListener('click', () => {
    createAddAdditionalServicesSelectForCurrentBooking(currentBookingData);
    showForm(document.body);
});

